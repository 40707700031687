import React from "react";
import "./CVcategories.css"

const CVcatSceno: React.FC = () => {


    return (
        <div className='CVcategories-container'>
            <div className='cvitem-cat'>
                Scenography
            </div>
            <div className='projects'>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2023
                    </div>
                    <div className='cvitem-info'>
                        “Plantage Dachau—Eine Begehung” <span className="oblique">Spielart München</span>
                    </div>
                </div>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2022
                    </div>
                    <div className='cvitem-info'>
                        “Don Quixote. Erster Ritt” <span className="oblique">Habibi Kiosk, Münchner Kammerspiele</span>
                    </div>
                </div>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2020
                    </div>
                    <div className='cvitem-info'>
                        “Messy History Lessons” <span className="oblique">Schwere Reiter München</span>
                    </div>
                </div>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2019
                    </div>
                    <div className='cvitem-info'>
                        “Between Worlds” (with Anne Horny) <span className="oblique">Ballhaus Ost</span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default CVcatSceno
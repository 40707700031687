import React from "react";
import "./CVcategories.css"

const CVcatGrant: React.FC = () => {


    return (
        <div className='CVcategories-container'>
            <div className='cvitem-cat'>
                Grants and Scholarships
            </div>
            <div className='projects'>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2023
                    </div>
                    <div className='cvitem-info'>
                        Research Grant by Fonds Darstellende Künste
                    </div>
                </div>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2022
                    </div>
                    <div className='cvitem-info'>
                        Nominee for the Prize of Fördergesellschaft ZKM/HfG
                    </div>
                </div>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2021
                    </div>
                    <div className='cvitem-info'>
                        Baden-Württemberg Scholarship
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CVcatGrant
import React, { useState } from "react";
import Vimeo from "@u-wave/react-vimeo";
import "./Infos.css"
import "../../fonts.css"
import CVcatGrant from "./CVcategories/CVcatGrant";
import CVcatExh from "./CVcategories/CVcatExh";
import CVcatPub from "./CVcategories/CVcatPub";
import CVcatRes from "./CVcategories/CVcatRes";
import CVcatSceno from "./CVcategories/CVcatSceno";
import CVcatWorksh from "./CVcategories/CVcatWorksh";
import Imprint from "../Imprint/Imprint";

export interface InfosProps {
    setShowImages: React.Dispatch<React.SetStateAction<boolean>>;
}

const Infos: React.FC<InfosProps> = ({setShowImages}) => {

    const handleBackButtonClick = () => {
        setShowImages(true); // Go back to the Images component
    };

    const [showVimeo, setShowVimeo] = useState<boolean>(false);

    return (
        <div className='info-page'>
            <header className="infos-header">
                <div></div>
                <button className="button-nav" onClick={handleBackButtonClick}>
                    &#60; BACK
                </button>
            </header>
            {/*<div className='video-container'>*/}
            {/*    <Vimeo video={889404807} autoplay={false} responsive={true} showTitle={true} width={300} height={300}/>*/}
            {/*</div>*/}
            {showVimeo ? <Vimeo video={889404807} autoplay={false} responsive={true} showTitle={true} color={'#ffff00'} showPortrait={false}/> : (
                <div>
                    <div className='info-container'>
                        <div className='info-text-1'>
                            Teresa Häußler (b. 1991) is an exhibition designer, scenographer, and curatorial
                            researcher based in Berlin. Her practice is situated at the intersection of
                            performing arts, exhibition design, and curatorial practice.
                            Based on thorough research she explores connections between space, politics,
                            and representation. Her current practice evolves around questions of
                            display, examining different layers of history and deconstructing narratives
                            of modernity. She graduated from Karlsruhe University of Arts and Design in 2023. 
                            She is a founding member of the collective Messy Archive Group.
                        </div>
                    </div>
                    {/*<div>*/}
                    {/*    <iframe src="https://player.vimeo.com/video/889404807?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen; picture-in-picture" title="Disparate Precedents of Display Teaser"></iframe>*/}
                    {/*</div>*/}

                    <div className='cv-container'>
                        <CVcatExh showVimeo={showVimeo} setShowVimeo={setShowVimeo}/>
                        <CVcatSceno/>
                        <CVcatGrant/>
                        <CVcatRes/>
                        <CVcatPub/>
                        <CVcatWorksh/>
                    </div>
                    <Imprint/>
                </div>

            )}

        </div>
    )
}

export default Infos
import React from "react";
import "./CVcategories.css"

export interface CVcatExhProps {
    showVimeo: boolean;
    setShowVimeo: React.Dispatch<React.SetStateAction<boolean>>;
}

const CVcatExh: React.FC<CVcatExhProps> = ({showVimeo, setShowVimeo}) => {

    const handleVimeoClick = () => {
        setShowVimeo(true);
    };

    return (
        <div className='CVcategories-container'>
            <div className='cvitem-cat'>
                Exhibitions
            </div>
            <div className='projects'>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2023
                    </div>
                    <div className='cvitem-info'>
                        Exhibition furniture for “Skin in the Game” <span className="oblique">KW institute of contemporary art</span>
                    </div>
                </div>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2023
                    </div>
                    <div className='cvitem-info'>
                        {/*<a href="https://vimeo.com/889404807?share=copy" target="_blank" rel="noopener noreferrer">*/}
                        {/*    Disparate Precedents of Display*/}
                        {/*</a>*/}
                        <a href="#" onClick={handleVimeoClick} style={{ color: 'red', textDecoration: 'none', cursor: 'pointer' }}>
                            “Disparate Precedents of Display”
                        </a>
                        <span className="oblique"> Diploma</span>
                    </div>
                    
                </div>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2022
                    </div>
                    <div className='cvitem-info'>
                        “Toward a Messy Archive” <span className="oblique">Annual Show of HfG Karlsruhe</span>
                    </div>
                </div>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2020
                    </div>
                    <div className='cvitem-info'>
                        “Glossary of Undisciplined Design” <span className="oblique">GfzK Leipzig</span>
                    </div>
                </div>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2019
                    </div>
                    <div className='cvitem-info'>
                        “Metabolic Museum-University” <span className="oblique">31st Biennial of Graphic Arts Ljubljana</span>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default CVcatExh
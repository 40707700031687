import './Imprint.css'

const Imprint: React.FC = () => {
    return (
      <div className='imprint-container'>
          <div className='imprint-heading'>Imprint</div>
          <div>
              <br/>

Website design and maintenance: Teresa Häußler<br/>
Font: nuyun by Barbara Acevedo Strange<br/>
Coding: Frank Bublitz<br/><br/>

Contact<br/><br/>

Teresa Häußler<br/>
Heinrich-Schlusnus-Straße 23<br/>
12057 Berlin<br/>
Email: teresahaussler@gmail.com<br/><br/>

Copyright<br/><br/> 

The content, design, and all elements of this website are protected by copyright and other intellectual property laws. Despite careful content control, I assume no liability for the content of external links. All works, designs, images, texts, and graphics are the intellectual property of Teresa Häußler, if not noted otherwise. They are protected by copyright according to §3 Paragraph 1 and 2 of the Copyright Act. Any use or reproduction requires the written consent of Teresa Häußler.

If you believe that any content on this website infringes upon your copyright, please contact me at teresahaussler@gmail.com with detailed information about the alleged infringement.

<br/><br/>Privacy Policy<br/><br/>

Responsible in terms of the data protection act:<br/>
Teresa Häußler<br/>
Heinrich-Schlusnus-Straße 23<br/>
12057 Berlin<br/><br/>

Introduction<br/>
This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you visit our website. Please read this policy carefully.
Information We Collect<br/>
  • Personal Information<br/>
  • Non-personal Information (e.g., browser type, IP address, etc.)<br/>
How We Use Your Information<br/>
  • Provide and maintain our website.<br/>
  • Improve, personalize, and expand our website.<br/><br/>
Disclosure of Your Information<br/>
We may share your information with third parties only as described in this Privacy Policy and in compliance with applicable data protection laws.
<br/><br/>Cookies and Similar Technologies<br/>
We use cookies and similar technologies to collect and track information about your use of our website. You can manage your cookie preferences through your browser settings.
<br/><br/>Your Rights<br/>
You have the right to free information about your stored data as well as the right to correction, deletion or blocking at any time. Contact us at teresahaussler@gmail.com for assistance.
<br/><br/>Security<br/>
We implement reasonable security measures to protect your information. However, no data transmission over the internet can be guaranteed as completely secure.
<br/><br/>Changes to This Privacy Policy<br/>
We may update our Privacy Policy from time to time. Please review this page periodically for changes.
<br/><br/>Contact Us<br/>
If you have questions or concerns about our Privacy Policy, please contact us at teresahaussler@gmail.com<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>


              
          </div>

      </div>
    );
}

export default Imprint;

import React from "react";
import "./CVcategories.css"

const CVcatWorksh: React.FC = () => {


    return (
        <div className='CVcategories-container'>
            <div className='cvitem-cat'>
                Workshops
            </div>
            <div className='projects'>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2023
                    </div>
                    <div className='cvitem-info'>
                        Reading Group at “Shared spaces—Architekturen kollektiver Hingabe” <span className="oblique">LAF Pforzheim</span>
                    </div>
                </div>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2022
                    </div>
                    <div className='cvitem-info'>
                        Reading Group with Messy Archive Group <span className="oblique">Breaking the Spell, Schauspiel Leipzig</span>
                    </div>
                </div>
                <div className='cvitem-container'>
                    <div className='cvitem-year'>
                        2022
                    </div>
                    <div className='cvitem-info'>
                        Reading and screen printing workshops <span className="oblique">Habibi Kiosk, Münchner Kammerspiele</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CVcatWorksh
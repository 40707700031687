import React, {useState} from 'react';
import './App.css';
import Images from './Components/Images/ImagesGallery';
import Infos from './Components/Infos/Infos'; // Import the CVItem1 component

function App() {
    const [showImages, setShowImages] = useState(true); // State to toggle between Images and CVItem1

    return (
        <div className="App">
            <div>
                {showImages ? <Images setShowImages={setShowImages}/> :
                    <Infos setShowImages={setShowImages}/>} {/* Conditionally render Images or CVItem1 */}
            </div>
        </div>
    );
}

export default App;


// (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <Images></Images>
//         <a
//             className="App-link"
//             href="https://reactjs.org"
//             target="_blank"
//             rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
// )

